/* eslint-disable @trello/disallow-filenames */
import { l } from 'app/scripts/lib/localize';

import cardBack_cs from 'resources/images/create-first-board/card-back/cardBack-cs.svg';
import cardBack_de from 'resources/images/create-first-board/card-back/cardBack-de.svg';
import cardBack_en from 'resources/images/create-first-board/card-back/cardBack-en.svg';
import cardBack_es from 'resources/images/create-first-board/card-back/cardBack-es.svg';
import cardBack_fi from 'resources/images/create-first-board/card-back/cardBack-fi.svg';
import cardBack_fr from 'resources/images/create-first-board/card-back/cardBack-fr.svg';
import cardBack_hu from 'resources/images/create-first-board/card-back/cardBack-hu.svg';
import cardBack_it from 'resources/images/create-first-board/card-back/cardBack-it.svg';
import cardBack_ja from 'resources/images/create-first-board/card-back/cardBack-ja.svg';
import cardBack_nb from 'resources/images/create-first-board/card-back/cardBack-nb.svg';
import cardBack_nl from 'resources/images/create-first-board/card-back/cardBack-nl.svg';
import cardBack_pl from 'resources/images/create-first-board/card-back/cardBack-pl.svg';
import cardBack_pt_br from 'resources/images/create-first-board/card-back/cardBack-pt-br.svg';
import cardBack_ru from 'resources/images/create-first-board/card-back/cardBack-ru.svg';
import cardBack_sv from 'resources/images/create-first-board/card-back/cardBack-sv.svg';
import cardBack_th from 'resources/images/create-first-board/card-back/cardBack-th.svg';
import cardBack_tr from 'resources/images/create-first-board/card-back/cardBack-tr.svg';
import cardBack_uk from 'resources/images/create-first-board/card-back/cardBack-uk.svg';
import cardBack_vi from 'resources/images/create-first-board/card-back/cardBack-vi.svg';
import cardBack_zh_hans from 'resources/images/create-first-board/card-back/cardBack-zh-hans.svg';
import cardBack_zh_hant from 'resources/images/create-first-board/card-back/cardBack-zh-hant.svg';
import cs_card_drag from 'resources/images/create-first-board/card-drag/cs-card-drag.gif';
import de_card_drag from 'resources/images/create-first-board/card-drag/de-card-drag.gif';
import en_card_drag from 'resources/images/create-first-board/card-drag/en-card-drag.gif';
import es_card_drag from 'resources/images/create-first-board/card-drag/es-card-drag.gif';
import fi_card_drag from 'resources/images/create-first-board/card-drag/fi-card-drag.gif';
import fr_card_drag from 'resources/images/create-first-board/card-drag/fr-card-drag.gif';
import hu_card_drag from 'resources/images/create-first-board/card-drag/hu-card-drag.gif';
import it_card_drag from 'resources/images/create-first-board/card-drag/it-card-drag.gif';
import ja_card_drag from 'resources/images/create-first-board/card-drag/ja-card-drag.gif';
import nb_card_drag from 'resources/images/create-first-board/card-drag/nb-card-drag.gif';
import nl_card_drag from 'resources/images/create-first-board/card-drag/nl-card-drag.gif';
import pl_card_drag from 'resources/images/create-first-board/card-drag/pl-card-drag.gif';
import pt_br_card_drag from 'resources/images/create-first-board/card-drag/pt-br-card-drag.gif';
import ru_card_drag from 'resources/images/create-first-board/card-drag/ru-card-drag.gif';
import sv_card_drag from 'resources/images/create-first-board/card-drag/sv-card-drag.gif';
import th_card_drag from 'resources/images/create-first-board/card-drag/th-card-drag.gif';
import tr_card_drag from 'resources/images/create-first-board/card-drag/tr-card-drag.gif';
import uk_card_drag from 'resources/images/create-first-board/card-drag/uk-card-drag.gif';
import vi_card_drag from 'resources/images/create-first-board/card-drag/vi-card-drag.gif';
import zh_hans_card_drag from 'resources/images/create-first-board/card-drag/zh-hans-card-drag.gif';
import zh_hant_card_drag from 'resources/images/create-first-board/card-drag/zh-hant-card-drag.gif';

export const BOARD_NAME = 'board-name';
export const LIST_NAME = 'list-name';
export const CARD_NAME = 'card-name';
export const CHECKITEM_NAME = 'check-name';
export const CARD_DRAG = 'card-drag';
export const translatedCardDragFileMap = {
  cs: cs_card_drag,
  de: de_card_drag,
  en: en_card_drag,
  es: es_card_drag,
  fi: fi_card_drag,
  fr: fr_card_drag,
  hu: hu_card_drag,
  it: it_card_drag,
  ja: ja_card_drag,
  nb: nb_card_drag,
  nl: nl_card_drag,
  pl: pl_card_drag,
  'pt-br': pt_br_card_drag,
  ru: ru_card_drag,
  sv: sv_card_drag,
  th: th_card_drag,
  tr: tr_card_drag,
  uk: uk_card_drag,
  vi: vi_card_drag,
  'zh-hans': zh_hans_card_drag,
  'zh-hant': zh_hant_card_drag,
};
export const translatedCardBackFileMap = {
  cs: cardBack_cs,
  de: cardBack_de,
  en: cardBack_en,
  es: cardBack_es,
  fi: cardBack_fi,
  fr: cardBack_fr,
  hu: cardBack_hu,
  it: cardBack_it,
  ja: cardBack_ja,
  nb: cardBack_nb,
  nl: cardBack_nl,
  pl: cardBack_pl,
  'pt-br': cardBack_pt_br,
  ru: cardBack_ru,
  sv: cardBack_sv,
  th: cardBack_th,
  tr: cardBack_tr,
  uk: cardBack_uk,
  vi: cardBack_vi,
  'zh-hans': cardBack_zh_hans,
  'zh-hant': cardBack_zh_hant,
};
export const initialData = {
  name: '',
  checkItem: '',
  // eslint-disable-next-line @trello/no-module-logic
  defaultValue: l(['templates', 'onboarding', `board-name:title`]),
  lists: [
    {
      // eslint-disable-next-line @trello/no-module-logic
      name: l(['templates', 'onboarding', `list-name:placeholder:0`]),
      cards: [
        {
          name: '',
          large: false,
          // eslint-disable-next-line @trello/no-module-logic
          defaultValue: l([
            'templates',
            'onboarding',
            `card-name:placeholder:0`,
          ]),
        },
        {
          name: '',
          large: false,
        },
        {
          name: '',
          large: false,
        },
      ],
    },
    {
      // eslint-disable-next-line @trello/no-module-logic
      name: l(['templates', 'onboarding', `list-name:placeholder:1`]),
      cards: [
        {
          name: '',
          large: true,
        },
        {
          name: '',
          large: false,
        },
        {
          name: '',
          large: true,
        },
      ],
    },
    {
      // eslint-disable-next-line @trello/no-module-logic
      name: l(['templates', 'onboarding', `list-name:placeholder:2`]),
      cards: [
        {
          name: '',
          large: false,
        },
        {
          name: '',
          large: true,
        },
        {
          name: '',
          large: false,
        },
      ],
    },
  ],
};
