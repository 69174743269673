/* eslint-disable @trello/disallow-filenames */
import { currentLocale } from '@trello/locale';

const getLocale = function () {
  const lowerCaseLocale = currentLocale.toLowerCase();
  const localeCode = (() => {
    switch (false) {
      case lowerCaseLocale !== 'zh-tw':
        return 'zh-hant';
      case lowerCaseLocale !== 'zh-cn':
        return 'zh-hans';
      case lowerCaseLocale !== 'zh-hant':
        return 'zh-hant';
      case lowerCaseLocale !== 'zh-hans':
        return 'zh-hans';
      case lowerCaseLocale !== 'pt-br':
        return 'pt-br';
      default:
        return lowerCaseLocale.split('-')[0];
    }
  })();

  return localeCode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTranslatedFile = function (fileMap: any, locale: any) {
  const localeCode = getLocale();
  const fileKeys = Object.keys(fileMap);
  if (fileKeys.includes(localeCode)) {
    return fileMap[localeCode];
  }
  return fileMap['en'];
};

export { getLocale, getTranslatedFile };
