/* eslint-disable @trello/disallow-filenames */
import _ from 'underscore';

const getQueryParams = function () {
  const queryString = window.location.search;
  return _.chain(queryString.substring(1).split('&'))
    .map((param) => param.split('='))
    .object()
    .value();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getQueryParamByKey = (key: any) => getQueryParams()[key];
