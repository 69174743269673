// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS201: Simplify complex destructure assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import _ from 'underscore';

import { renderReactRoot } from '@trello/component-wrapper';
import { ErrorBoundary } from '@trello/error-boundaries';
import { getFeatureGateAsync } from '@trello/feature-gate-client';
import ReactDOM from '@trello/react-dom-wrapper';
import { navigate } from '@trello/router/navigate';

import { Auth } from 'app/scripts/db/Auth';
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { View } from 'app/scripts/views/internal/View';
import { Alerts } from 'app/scripts/views/lib/Alerts';
import { CreateFirstBoard as CreateFirstBoardComponent } from 'app/scripts/views/onboarding/CreateFirstBoard';
const t = teacupWithHelpers('onboarding');

class CreateFirstBoardView extends View {
  static initClass() {
    this.prototype.tagName = 'div';
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type '() => str... Remove this comment to see the full error message
    this.prototype.className = 'first-board-wrapper';
  }

  async renderReactComponent() {
    const isRenderReactRootEnabled = await getFeatureGateAsync(
      'tplat_fg_migrate_createfirstboardview',
    );
    if (isRenderReactRootEnabled) {
      renderReactRoot(
        <ErrorBoundary
          tags={{
            ownershipArea: 'trello-web-eng',
            feature: 'Madlibs',
          }}
        >
          <CreateFirstBoardComponent
            onSubmit={
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (board: any) => this.createBoard(board)
            }
          />
        </ErrorBoundary>,
        this.el.querySelector('.js-create-first-board'),
      );
    } else {
      ReactDOM.render(
        <ErrorBoundary
          tags={{
            ownershipArea: 'trello-web-eng',
            feature: 'Madlibs',
          }}
        >
          <CreateFirstBoardComponent
            onSubmit={
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (board: any) => this.createBoard(board)
            }
          />
        </ErrorBoundary>,
        // @ts-expect-error TS(2345): Argument of type 'Element | null' is not assignabl... Remove this comment to see the full error message
        this.el.querySelector('.js-create-first-board'),
      );
    }
  }

  render() {
    this.$el.html(
      t.renderable(() => t.div('.first-board-wrapper.js-create-first-board')),
    );

    this.renderReactComponent();

    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createCollection(inputs: any, collection: any, ...rest: any[]) {
    const [attrs] = Array.from(rest.slice(0, rest.length - 0));
    return (
      inputs
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((d: any) => d.name !== '')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map(({ name }: any, index: any) => {
          return collection.create({
            name,
            pos: index + 1,
            creationMethod: 'assisted',
            ...attrs,
          });
        })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createBoard(board: any) {
    const initialData = { name: board.name };
    const requestData = {
      prefs_permissionLevel: 'private',
      prefs_selfJoin: true,
      prefs_background: 'lime',
      creationMethod: 'assisted',
      defaultLists: false,
      ...initialData,
    };

    const newBoard = Auth.me().boardList.create(initialData, {
      // @ts-expect-error
      requestData,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
      success: (board: any) => {
        // this fixes an issue with idBoards not being updated at the time that
        // the board gets rendered and the client thinking the member is not on
        // the board
        let currentBoards, needle;
        if (
          ((needle = board.id),
          !Array.from((currentBoards = Auth.me().get('idBoards'))).includes(
            needle,
          ))
        ) {
          return Auth.me().set('idBoards', currentBoards.concat(board.id));
        }
      },
      error: () => {
        return Alerts.flash(
          'could not create board',
          'error',
          'createBoardError',
        );
      },
    });

    return this.listenTo(this.model.boardList, 'sync', () => {
      const newLists = this.createCollection(board.lists, newBoard.listList);
      return this.listenTo(newLists[0], 'sync', () => {
        const newCards = this.createCollection(
          board.lists[0].cards,
          newLists[0].cardList,
        );
        const checklistInput = board.checkItem;
        if (_.isEmpty(checklistInput)) {
          return this.navigateToNewBoard(newBoard);
        } else {
          return this.listenTo(newCards[0], 'sync', () => {
            const checklist = this.createCollection(
              ['Checklist'],
              newCards[0].checklistList,
              { idCard: newCards[0].id },
            )[0];
            return this.listenTo(checklist, 'sync', () => {
              const checkItem = this.createCollection(
                [{ name: checklistInput }],
                checklist.checkItemList,
              )[0];
              return this.listenTo(checkItem, 'sync', () => {
                // if we navigate before the checklist item is synced, you get this weird experience where the
                // item exists on the card, but not yet in the card preview
                return this.navigateToNewBoard(newBoard);
              });
            });
          });
        }
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigateToNewBoard(board: any) {
    Auth.me().dismiss('create-first-board');
    const relativeBoardUrl = new URL(board.attributes.url).pathname;
    navigate(`${relativeBoardUrl}?openCardComposerInFirstList=true`, {
      trigger: true,
    });
  }
}

CreateFirstBoardView.initClass();
export { CreateFirstBoardView };
