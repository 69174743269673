/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import BluebirdPromise from 'bluebird';
import { isValid, startOfDay } from 'date-fns';

import { ApiError } from '@trello/error-handling';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import { forTemplate } from '@trello/legacy-i18n';
import { navigate } from '@trello/router/navigate';

import { getCardUrl } from 'app/scripts/controller/urls';
import { Auth } from 'app/scripts/db/Auth';
import { ModelLoader } from 'app/scripts/db/model-loader';
import { getQueryParamByKey } from 'app/scripts/lib/util/url/get-query-param-by-key';
import { setDocumentTitle } from 'app/src/components/DocumentTitle';
import { getSpinner } from 'app/src/getSpinner';
import type { Card } from '../models/Card';
import type { BoardSettings, CardDetailOptions } from './BoardPage.types';
import { controllerEvents } from './controllerEvents';

const l = forTemplate('home');

const parseMaybe = function (str: string) {
  const result = parseInt(str, 10);
  if (isNaN(result)) {
    return undefined;
  } else {
    return result;
  }
};

export const BoardPage = {
  cardDetailViewChunkLoaded: false,

  boardReferralPage(
    shortLink: string,
    name: string,
    referrerUsername?: string | null | undefined,
  ) {
    return this.boardPage(shortLink, '', referrerUsername);
  },

  boardPage(
    shortLink: string,
    path: string | null | undefined,
    referrerUsername?: string | null | undefined,
  ): BluebirdPromise<void> {
    // :slug
    // :slug/:section
    // :slug/map
    // :slug/member/:username
    // :slug/calendar/:year/:month
    // :slug/calendar/:year/:month/:day
    // :slug/power-ups/enabled
    // :slug/power-ups/category/:category
    // :slug/power-up/:powerup
    // :slug/power-up/:powerup/enable
    // :slug/power-up/:powerup/view/:key
    // :slug/butler
    // :slug/butler/:butlerTab
    // :slug/butler/:butlerTab/edit/:butlerCmd
    // :slug/butler/:butlerTab/new
    // :slug/butler/:butlerTab/log/:butlerCmd
    // :slug/butler/reports/:reportType
    // :slug/butler/edit/:butlerCmd
    // :slug/butler/log/:butlerCmd
    // :slug/timeline
    // :slug/table
    // :slug/calendar-view
    // :slug/map/:lat/:lng/:zoom
    if (path == null) {
      path = '';
    }
    const [, section, ...subsections] = Array.from(
      path.split('?')[0].split('/'),
    );

    const settings: BoardSettings = { idBoard: shortLink, referrerUsername };

    if (section === 'calendar') {
      const calendarDate = startOfDay(new Date());
      const year = parseMaybe(subsections[0]);
      if (year) {
        calendarDate.setFullYear(year);
      }
      const month = parseMaybe(subsections[1]);
      if (month) {
        calendarDate.setMonth(month - 1);
      }
      const day = parseMaybe(subsections[2]);
      if (day) {
        calendarDate.setDate(day);
      }
      if (isValid(calendarDate)) {
        settings.calendarDate = calendarDate;
      }
    }
    // need both lat and long for centering
    if (section === 'map' && subsections[0] != null && subsections[1] != null) {
      const lat = parseFloat(subsections[0]);
      const lng = parseFloat(subsections[1]);
      const zoom = parseInt(subsections[2], 10);
      if (lat && lng) {
        settings.mapCenterTo = { lat, lng };
      }
      if (zoom) {
        settings.zoom = zoom;
      }
    }

    if (section === 'member' && subsections[0] != null) {
      settings.usernameBoardProfile = subsections[0];
    }

    if (section === 'power-ups' && subsections[0] != null) {
      settings.directorySection = subsections[0];
      if (subsections[1] != null) {
        settings.directoryCategory = subsections[1];
      }
    }

    if (section === 'power-up' && subsections[0] != null) {
      settings.directoryIdPowerUp = subsections[0];

      if (subsections[1] != null && subsections[1] === 'enable') {
        settings.directoryIsEnabling = true;
      } else if (subsections[1] === 'view' && subsections[2] != null) {
        settings.powerUpViewKey = subsections[2];
      }
    }

    if (section === 'butler' && subsections.length) {
      if (subsections[0] === 'edit' && subsections[1]) {
        settings.butlerCmdEdit = subsections[1];
      } else if (subsections[0] === 'log' && subsections[1]) {
        settings.butlerCmdLog = subsections[1];
      } else if (subsections[0] === 'reports' && subsections[1]) {
        settings.reportType = subsections[1];
      } else {
        settings.butlerTab = subsections[0];
        if (subsections[1] && subsections[1] === 'new') {
          settings.butlerCmdEdit = subsections[1];
        } else if (
          subsections[1] &&
          subsections[1] === 'edit' &&
          subsections[2]
        ) {
          settings.butlerCmdEdit = subsections[2];
        } else if (
          subsections[1] &&
          subsections[1] === 'log' &&
          subsections[2]
        ) {
          settings.butlerCmdLog = subsections[2];
        } else if (subsections[1] && subsections[1] === 'usage') {
          settings.butlerUsage = subsections[1];
        } else if (subsections[1] && subsections[1] === 'account') {
          settings.butlerAccount = subsections[1];
        }
      }
    }

    if (
      [
        'calendar',
        'member',
        'power-up',
        'power-ups',
        'map',
        'butler',
        'timeline',
        'table',
        'calendar-view',
        'dashboard',
      ].includes(section)
    ) {
      settings.section = section;
    }

    // @ts-expect-error TS(2339): Property 'boardPageSettings' does not exist on typ... Remove this comment to see the full error message
    this.boardPageSettings = settings;

    // @ts-expect-error TS(2339): Property 'displayBoard' does not exist on type '{ ... Remove this comment to see the full error message
    return this.displayBoard(settings).done();
  },

  oldBoardPage(path: string) {
    // :id
    // :slug/:id
    // :slug/:id/:section
    // :slug/:id/member/:username

    const settings: {
      idBoard?: string;
      section?: string;
      usernameBoardProfile?: string;
    } = {};

    const parts = path.split('/');
    if (parts.length === 1) {
      settings.idBoard = parts[0];
    } else {
      settings.idBoard = parts[1];
    }

    if (parts.length === 3) {
      settings.section = parts[2];
    } else if (parts.length === 4) {
      settings.usernameBoardProfile = parts[3];
    }

    // @ts-expect-error TS(2339): Property 'displayBoard' does not exist on type '{ ... Remove this comment to see the full error message
    return this.displayBoard(settings).done();
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardPage(shortLink: string): any {
    const highlight =
      location.hash.length > 1
        ? // Remove leading #
          location.hash.substr(1)
        : null;
    const replyToComment = getQueryParamByKey('replyToComment');
    // @ts-expect-error TS(2339): Property 'displayBoard' does not exist on type '{ ... Remove this comment to see the full error message
    return this.displayBoard({
      idCard: shortLink,
      highlight,
      replyToComment,
    }).done();
  },

  oldCardPage(slug: string, idBoard: string, cardComponent: string) {
    // cardComponent can be one of several things.
    //
    // It could be an id, or it could be a shortLink, which are both fine.
    // *Or* it could be an idShort, if it's a #123-style link in an old
    // notification.
    //
    // We don't want displayBoard to have to special-case this one annoying
    // scenario (it's the only case where you need to use the idBoard in order
    // to determine the idCard), so we normalize the route here to maintain
    // backwards compatibility.

    const isIdShort = (str: string) => /^\d+$/.test(str);

    const idCardPromise = (() => {
      if (isIdShort(cardComponent)) {
        // Dependency required at call site to avoid import cycles, do not lift to top of module
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { ModelLoader } = require('app/scripts/db/model-loader');
        return ModelLoader.loadCardId(idBoard, cardComponent).catch(
          ApiError,
          () => null,
        );
      } else {
        return BluebirdPromise.resolve(cardComponent);
      }
    })();

    return idCardPromise.then((idCard: string | null) => {
      // @ts-expect-error TS(2339): Property 'displayBoard' does not exist on type '{ ... Remove this comment to see the full error message
      return this.displayBoard({ idBoard, idCard }).done();
    });
  },

  showCardDetail(card: Card, param?: CardDetailOptions) {
    if (param == null) {
      param = {};
    }
    const { highlight, replyToComment, onAbort } = param;
    if (card == null) {
      if (onAbort) {
        onAbort(new Error('card is null'));
      }
      return;
    }
    // @ts-expect-error
    navigate(getCardUrl(card, highlight, replyToComment), {
      trigger: true,
    });
    if (onAbort) {
      onAbort(new Error('card is on another board'));
    }
    return;
  },

  createFirstBoardPage() {
    return BluebirdPromise.using(getSpinner(), () => {
      return ModelLoader.await('boardsData').then(() => {
        const me = Auth.me();
        if (!me.isLoggedIn()) {
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
          return (window.location = '/login');
        }

        const memberHasBoards = me.boardList.length > 0;
        if (memberHasBoards || me.isDismissed('create-first-board')) {
          return navigate('/', { trigger: true });
        }

        controllerEvents.trigger('clearPreviousView');
        controllerEvents.trigger('setViewType', 'create-first-board');
        setDocumentTitle(l('orientation-create-board-button'));
        // Dependency required at call site to avoid import cycles, do not lift to top of module
        const {
          CreateFirstBoardView,
        } = require('app/scripts/views/onboarding/CreateFirstBoardView');
        // @ts-expect-error TS(2339): Property 'topLevelView' does not exist on type '{ ... Remove this comment to see the full error message
        const view = this.topLevelView(CreateFirstBoardView, me);
        $('[data-js-id="header-container"]').hide();
        $('#content').html(view.render().el);
      });
    }).done();
  },
};
